import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUserFullname, selectUserProfile } from "../redux/userStateSlice";
import { selectCourseById } from '../redux/coursesSlice'
import { showSignIn, showSignUp, openBuyCoursePage } from "../redux/appStateSlice";
import { useParams } from 'react-router-dom'
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import ListItem from "@material-ui/core/ListItem";
import { useHistory } from "react-router";
import clsx from "clsx";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    borderBottom: "1px solid #DCDCDC",

  },
  buyButton: {
    minWidth: 200,
    textTransform: "none",
    marginRight: 20
  },
  row: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "space-between",
    marginLeft: '4px',
    marginTop: '7px',
    paddingRight: '49px',
    '@media (max-width: 600px)':{
      marginLeft: '15px',
      paddingRight:'15px'
    }
  },
  searchRoot: {
    width: "100%",
    margin: "0px 20px",
    // '@media (max-width: 700px)': {
    //     display: 'none'
    // }
  },
  searchRoot2: {
    width: "100%",
    padding: "0 12px",
    // '@media (min-width: 700px)': {
    //     display: 'none'
    // }
  },
  searchOutline: {
    margin: "10px 0px",
    borderRadius: 20,
  },
  rightItems: {
    display: "flex",
    marginRight: 60,
    "& > * ": {
      marginLeft: "45px",
    },
  },
  grow: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  profile: {
    marginRight: 20,
  },
  logo: {
    width: 60,
    height: 52
  },
  "@media (max-width: 400px)": {
    rightItems: {
      "& > * ": {
        marginLeft: "0px",
        marginRight: "0px",
      },
      marginRight: 0,
    },
  },
  disableTextTransform: {
    textTransform: "none",
  },
  blackColor: {
    color: "black",
  },
  signInButtonText: {
    fontWeight: 600,
    fontSize: 15,
  },
  signInButtonRoot: {
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
    marginRight: 10
  },
  rootMenu:{
    display:'flex',
    paddingLeft: 10,
    paddingTop: 10
  },
  listItemRoot:{
    paddingTop: 1,
    paddingLeft: 0,
    display: 'flex',
    flexDirection: "column",
    alignItems:'flex-start'
  },
  menuItemRoot:{
    paddingLeft: 2,
    paddingTop: 0,
    '@media (max-width:600px)':{
      minHeight: 0
    }
  }
}));

const ProfileMenu = (props) => {
  const classes = useStyles()
  const { UserProfile } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showLogoutPromt, setShowLogoutPromt] = useState(false);
  const dispatch = useDispatch();

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setShowLogoutPromt(true);
  };

  const handleLogout2 = () => {
    dispatch(logout());
  };

  return (
    <>
      <IconButton style={{ backgroundColor: "#5EB6DC", color: "#fff", height:40, width:40, fontSize: 15, paddingTop: 10}} onClick={handleMenuClick}>
        {UserProfile.first_name[0]}
        {UserProfile.last_name[0].toUpperCase()}
      </IconButton>
      <Menu
        id="appbar-profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{list: classes.rootMenu}}
      >
        <IconButton style={{ backgroundColor: "#5EB6DC", color: "#fff", height:40, width:40, fontSize: 15}} onClick={handleMenuClick}>
        {UserProfile.first_name[0]}
        {UserProfile.last_name[0].toUpperCase()}
      </IconButton>
        <div style={{display:'flex', flexDirection: 'column', paddingLeft: 10}}>
          <ListItem classes={{root: classes.listItemRoot}}>
            {UserProfile.first_name} {UserProfile.last_name}
          <div style={{fontSize: 13, color: "grey"}}>{UserProfile.email}</div>
          </ListItem>
          
          <div style={{display:'flex'}}>
            <ExitToAppIcon />
            {/* <img style={{height: 20, width:20 }} src="/images/logout.png" alt="logo" /> */}
            <MenuItem classes={{root: classes.menuItemRoot}} onClick={handleLogout}>Chiqish</MenuItem>
          </div>
        </div>
      </Menu>
      <Dialog open={showLogoutPromt}>
        <DialogContent>
          <DialogContentText>Aniq chiqmoqchimisiz?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleLogout2}>
            Ha
          </Button>
          <Button color="primary" onClick={() => setShowLogoutPromt(false)}>
            Yo`q
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AppBar = ({ title }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleSignIn = () => {
    dispatch(showSignIn());
  };
  const handleSignUp = () => {
    dispatch(showSignUp());
  };

  const match = useRouteMatch({
    path: '/course/:courseID',
    exact: true
  })
  const courseID = parseInt((match && match.params["courseID"]) ?? 0);
  const course = useSelector((state) => selectCourseById(state, courseID));
  const dispatch = useDispatch();
  const userFullName = useSelector(selectUserFullname);
  const UserProfile = useSelector(selectUserProfile);

  const handleBuy = () => {
    dispatch(openBuyCoursePage(courseID));
  }

  const handleHomeClick = (e) => {
    e.preventDefault();
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Hidden xsDown> 
        <div
            className={classes.grow}
            style={{
              maxWidth: "50px",
            }}
          /> 
        </Hidden>
          
        <a href="/" onClick={handleHomeClick}>
          <img className={classes.logo} src="/images/logo.png" alt="logo" />
        </a>
        <Hidden xsDown>
          <div className={classes.grow} />
        </Hidden>

        {title && (
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
        )}
        {/* {!title && (
          <Hidden xsDown>
            <TextField
              variant="outlined"
              InputProps={{
                endAdornment: <SearchIcon />,
                classes: {
                  root: classes.searchRoot,
                  notchedOutline: classes.searchOutline,
                },
              }}
            />
          </Hidden>
        )} */}
        {/* <div className={classes.rightItems}>
          <Button
            classes={{
              label: clsx(classes.disableTextTransform, classes.blackColor),
            }}
          >
            Kurslar
          </Button>
          <Button
            classes={{
              label: clsx(classes.disableTextTransform, classes.blackColor),
            }}
          >
            Biz haqimizda
          </Button>
          
        </div> */}
        <Hidden xsDown>

          {course && !course.has_bought &&
            (<Button variant="contained" color="primary" className={classes.buyButton} onClick={handleBuy} >
              Kursga obuna bulish
            </Button>
            )}

        {!userFullName && (
            <Button
              classes={{
                root: classes.signInButtonRoot,
                label: clsx(
                  classes.disableTextTransform,
                  classes.signInButtonText
                ),
              }}
              variant="outlined"
              color="primary"
              onClick={handleSignUp}
            >
              Ro`yhatdan o`tish
            </Button>
          )}
        </Hidden>
        {!userFullName && (
            <Button
              classes={{
                root: classes.signInButtonRoot,
                label: clsx(
                  classes.disableTextTransform,
                  classes.signInButtonText
                ),
              }}
              variant="outlined"
              color="primary"
              onClick={handleSignIn}
            >
              Kirish
            </Button>
          )}
        
        {userFullName && <ProfileMenu UserProfile={UserProfile} />}
      </div>
      {/* {
        <Hidden smUp>
          <TextField
            classes={{
              root: classes.searchRoot2,
            }}
            variant="outlined"
            InputProps={{
              endAdornment: <SearchIcon />,
              classes: {
                root: classes.searchRoot2,
                notchedOutline: classes.searchOutline,
              },
            }}
          />
        </Hidden>
      } */}
    </div>
  );
};

export default AppBar;
