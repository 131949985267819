import axios from 'axios'
import parseResponse from '../utils/parseResponse'
import baseURL from './baseurl'

export const getCourses = async () => {
    return parseResponse(await axios.get(`${baseURL}/courses`))
}

export const getCourseFull = async (courseID, accessToken) => {
    return parseResponse(await axios.get(`${baseURL}/courses/${courseID}/v2`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}