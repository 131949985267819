import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import BaseComponent from './BaseComponent'


const useStyles = makeStyles(theme => ({
    questionContainer: {
        marginBottom: 12,
        display: 'flex',
        alignItems: 'flex-start',
    },
    question: {
        marginLeft: 10,
        fontSize: 15,
        fontWeight: 500,
        lineHeight: '18px',
        whiteSpace: 'pre-wrap'
    },
    answer: {
        display: 'block',
        fontSize: 15,
        lineHeight: '32px',
        color: '#3F3F3F',
        textTransform: 'none',
    },
    pointer: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main
        },
        '&:active': {
            color: theme.palette.primary.main
        }
    },
    selected: {
        //color: theme.palette.primary.main,
        fontWeight: 700,
        backgroundColor: '#178FC222',
       // width: '50%',
        textAlign: 'left',
        // paddingTop: 0,
        // paddingBottom: 0,
    },
    correct: {
        color: 'green',
        fontWeight: 700,
    },
    incorrect: {
        color: 'red',
        fontWeight: 700,
    },
    letter: {
        marginRight: 10,
        fontWeight: 600,
    }
}))

const LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

const Quiz = props => {
    
    const classes = useStyles()
    const {quiz, showResult, selectedAnswer, onAnswerSelected} = props
    const {position, question, variants, answer: correctAnswer} = quiz

    const handleSelectAnswer = answer => {
        if (!showResult) {
            onAnswerSelected(position, answer, answer === correctAnswer)
        }
    }

    return (
        <>
            <div className={classes.questionContainer}>
                <b>{position}.</b>
                <Typography variant="body1" className={classes.question}>
                     {question}
                </Typography>
            </div>
            
            {variants.map((variant, index) => {
                const selected = selectedAnswer === variant
                let className
                if (selected) {
                    className = classes.selected
                    if (showResult) {
                        className = selectedAnswer === correctAnswer ? classes.correct : classes.incorrect
                    }
                }

                let Parent = Button
                if (showResult) {
                    Parent = Typography
                }

                return (
                    <Parent
                        key={index}
                        className={clsx(classes.answer, className)}
                        onClick={() => handleSelectAnswer(variant)}
                    >
                        <span className={classes.letter}>{LETTERS[index]}.</span> {variant}
                    </Parent>
                )
            })}
        </>
    )
}

Quiz.propTypes = {
    quiz: PropTypes.object.isRequired,
    showResult: PropTypes.bool.isRequired,
    selectedAnswer: PropTypes.string,
    onAnswerSelected: PropTypes.func.isRequired,
}

const ExerciseQuiz = ({lessonID, exercise, onSaveProgress}) => {
    const exercises = useMemo(() => exercise.list.slice().sort((a, b) => a.position - b.position), [exercise])
    const [result, setResult] = useState({})
    const [correctAnswersCount, setCorrectAnswersCount] = React.useState(0)
    const [pageIndex, setPageIndex] = React.useState(0)
    const [showResult, setShowResult] = React.useState(false)

    const handleReset = () => {
        setShowResult(false)
        setCorrectAnswersCount(0)
        setResult({})
        setPageIndex(0)
    }

    useEffect(() => {
        handleReset()
    }, [lessonID])


    const quiz = exercises[pageIndex]
    if (!quiz) {
        return null
    }

    const handleAnswerSelected = (quizPosition, selectedAnswer, isCorrect) => {

        setResult({...result, [quizPosition]: {answer: selectedAnswer, correct: isCorrect}})

        if (pageIndex < exercises.length - 1) {
            setTimeout(() => {
                setPageIndex(pageIndex + 1)
            }, 600)
        }
    }
    const handleCheck = async () => {
        let count = 0
        for (const id in result) {
            if (result[id].correct === true) {
                count++
            }
        }
        setCorrectAnswersCount(count)
        setShowResult(true)

        if (onSaveProgress) {
            let progress = count * 100 / exercises.length
            onSaveProgress(progress)
        }
    }


    return (
        <BaseComponent
            title={`Exercise ${exercise.position}`}
            subtitle={exercise.title}
            currentPage={pageIndex}
            pagesCount={exercise.list.length}
            pagingStep={1}
            onPagesSelected={ index => setPageIndex(index)}
            correctAnswers={correctAnswersCount}
            totalQuestions={exercise.list.length}
            onCheck={handleCheck}
            onReset={handleReset}
            showResult={showResult}
            checkDisabled={showResult || Object.keys(result).length === 0}
            resetDisabled={!showResult}
        >
           <Quiz quiz={quiz} showResult={showResult} selectedAnswer={result[quiz.position]?.answer} onAnswerSelected={handleAnswerSelected}/>
        </BaseComponent>
    )
}

ExerciseQuiz.propTypes = {
    className: PropTypes.string,
    lessonID: PropTypes.number.isRequired,
    exercise: PropTypes.object.isRequired
}

export default ExerciseQuiz