import React, {useState} from 'react';
import {asyncSignIn, selectUserStateStatus, selectUserStateError} from '../../redux/userStateSlice'
import {showSignUp} from '../../redux/appStateSlice'
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux'
import {ServerError  } from "../../utils/Error";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://edvi.uz/">
        EDVI.UZ
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  main:{
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons:{
    marginTop: 16,
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#0a6186',
      color: 'white'
    }
  },
}));

const SignIn = (props) => {
  const {close} = props;
  const classes = useStyles();
  const dispatch = useDispatch()
  const authStatus = useSelector(selectUserStateStatus)
  const authError = useSelector(selectUserStateError)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  
  const pending = authStatus === 'pending'
  let invalidLoginOrPassword = false
  let validationError = false
  let errorText = null
  if (authError) {
    if (authError.name === 'ServerError') {
      switch (authError.code) {
        case ServerError.Code.InvalidLoginOrPassword:
          invalidLoginOrPassword = true
          errorText = 'Invalid login or password'
          break
        case ServerError.Code.ValidationError:
          validationError = true
          errorText = 'Please fill the fields with valid values'
          break
        default:
          errorText=authError.message
          break
      }
    }
    else {
      errorText=authError.message
    }
  }

  const handleSignUp = e => {
    e.preventDefault()
    dispatch(showSignUp())
  }
  const handleSubmit = e => {
    e.preventDefault()
    dispatch(asyncSignIn(email, password))
  }

  return (
    <Container className={classes.main}  component="main" maxWidth="xs">
      <CssBaseline />
        <CloseIcon onClick={close} className={classes.icons}/>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Kirish
        </Typography>
        {errorText && (
          <Typography variant="subtitle1" color="secondary">
            {errorText}
          </Typography>
        )}
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Typography>
            Kirish
          </Typography>
          <TextField
            style={{marginBottom: '15px'}}
            variant="outlined"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            placeholder="e-mail yoki telefon raqamingizni kiriting"
            autoFocus
            error={invalidLoginOrPassword || (validationError && password.length !== 0)}
            disabled={pending}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Typography>
          Parol
          </Typography>
          <TextField
            style={{marginBottom: '15px'}}
            variant="outlined"
            required
            fullWidth
            name="password"
            type="password"
            placeholder="Parol*"
            id="password"
            autoComplete="current-password"
            error={invalidLoginOrPassword || (validationError && password.length === 0)}
            disabled={pending}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Grid container>
            <Grid item xs={12} style={{textAlign:"center", }}>
              <Link href="#" variant="body2">
              Parolingizni unutdingizmi?
              </Link>
            </Grid>
            <Box style={{height:"46px"}}></Box>
            <Grid xs={12} item style={{textAlign:"center"}}>
              <Typography >
              Akkauntingiz hali yo'qmi?
              </Typography>
              <Link href="#" variant="body2" onClick={handleSignUp}>
                {"Ro'yxatdan o'tish"}
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={pending}
          >
            Kirish
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default SignIn