import React, {useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles";
import {  useDispatch, useSelector} from "react-redux" 
import CourseCard from './CourseCard'
import Button from '@material-ui/core/Button'
import CircularProgress from "@material-ui/core/CircularProgress";

import { fetchCourses, selectAllCoursesIds, selectLoadCoursesStatus } from "../../redux/coursesSlice";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        // overflowY: 'auto',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    showMoreButton: {
        width: 166,
        height: 40,
        borderRadius: 28,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main
    },
    showMoreLabel: {
        color: theme.palette.primary.main
    },
    footer: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 60,
    },
}))

const CoursesList = () => {
    const classes = useStyles()
    const courses = useSelector(selectAllCoursesIds)
    const status = useSelector(selectLoadCoursesStatus)
    const dispatch = useDispatch()
    useEffect(() => {
        if (status === 'idle' || status === 'rejected') {
            dispatch(fetchCourses())
        }
    }, [status])

    const needShowMoreButton = courses && courses.length > 3

    let component
    if (status === 'fulfilled') {
        component = courses.map(courseID => <CourseCard key={courseID} courseID={courseID}/>)
    }
    else {
        component = <CircularProgress size={100}/>
    }

    return (
        <>
            <div className={classes.container}>
                {component}
                {/* <CourseCard soon={true} key={2} courseID={2}/>
                <CourseCard soon={true} key={2} courseID={2}/> */}
            </div>
            {needShowMoreButton && (
                <div className={classes.footer}>
                <Button
                    classes={{
                        root: classes.showMoreButton,
                        label: classes.showMoreLabel
                    }}
                >
                    Show more
                </Button>
            </div>
            )}
        </>
    )
}

export default CoursesList