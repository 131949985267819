import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation} from 'react-router-dom'

import AuthForm from './Components/Auth/AuthForm'
import AppBar from './Components/AppBar'
import MainPage from './Components/MainPage/MainPage'
import CourseOverview from './Components/CourseOverview/CourseOverview'
import Course from './Components/Course/Course'
import Footer from './Components/Footer'
import BuyCourse from './Components/BuyCourse/BuyCourse'

import { useDispatch, useSelector } from 'react-redux'
import { asyncLoadUserState } from './redux/userStateSlice'
import { selectLastRoute, setLastRoute } from './redux/appStateSlice'

function App() {
  const history = useHistory()
  const dispatch = useDispatch()
  const lastRoute = useSelector(selectLastRoute)
  const location = useLocation()

  useEffect(() => {
    dispatch(setLastRoute(location.pathname))
  }, [location])

  useEffect(() => {
    dispatch(asyncLoadUserState())
    if (lastRoute) {
      history.push(lastRoute)
    }
  }, [])

  return (
      <div style={{maxWidth: 1240, width: '100%',  margin: '0 auto'}}>
        <AppBar/>
          <Switch>
            <Route path="/course/:courseID/overview">
              <CourseOverview/>
            </Route>
            <Route path="/course/:courseID">
              <Course/>
            </Route>
            <Route path="/">
              <MainPage/>
            </Route>
          </Switch>
        <Footer/>
        <AuthForm/>
        <BuyCourse/>
      </div>  
  );
}

export default App;
