const FreeIcon = () => (
	<svg height="24px"
		width="24px"
		viewBox="0 0 512 512">
		<g>
			<path style={{fill: 'rgb(233, 92, 92)'}} d="M216.332,236.363l-18.534,4.145c-0.515,0.112-0.712,0.423-0.592,0.945l5.174,23.13
				c0.113,0.515,0.423,0.712,0.938,0.599l18.533-4.145c7.924-1.777,12.203-7.832,10.546-15.248
				C230.706,238.245,224.249,234.587,216.332,236.363z"/>
			<path style={{ fill: 'rgb(233, 92, 92)' }} d="M512,255.996l-63.299-51.631l29.002-76.362l-80.641-13.07L384,34.3l-76.361,29.002L256,0.004l-51.638,63.299
				L128.007,34.3l-13.07,80.634l-80.634,13.07l28.995,76.362L0,255.996l63.299,51.632l-28.995,76.368l80.634,13.07l13.07,80.633
				l76.355-29.002L256,511.996l51.638-63.298L384,477.7l13.063-80.633l80.641-13.07l-29.002-76.368L512,255.996z M165.476,286.796
				l-33.613,7.522c-0.515,0.113-0.712,0.43-0.599,0.938l7.635,34.127c0.176,0.769-0.218,1.389-0.987,1.558l-12.527,2.805
				c-0.768,0.17-1.389-0.225-1.565-0.987l-18.872-84.356c-0.169-0.776,0.225-1.396,0.994-1.565l54.444-12.182
				c0.768-0.17,1.389,0.225,1.565,0.987l2.46,10.997c0.169,0.762-0.225,1.389-0.994,1.558l-39.88,8.925
				c-0.507,0.113-0.705,0.423-0.592,0.944l4.949,22.108c0.112,0.508,0.423,0.705,0.938,0.593l33.612-7.522
				c0.769-0.176,1.396,0.225,1.565,0.994l2.461,10.99C166.639,286,166.244,286.62,165.476,286.796z M261.88,303.208l-14.184,3.172
				c-1.015,0.226-1.614-0.043-2.2-0.846l-24.194-30.158l-14.952,3.349c-0.514,0.112-0.712,0.422-0.599,0.937l7.409,33.106
				c0.17,0.768-0.218,1.389-0.994,1.565l-12.52,2.799c-0.776,0.176-1.396-0.219-1.565-0.988l-18.872-84.355
				c-0.176-0.768,0.219-1.389,0.987-1.565l33.613-7.522c15.981-3.574,30.109,4.406,33.542,19.739
				c2.545,11.378-2.002,21.656-11.146,27.592l26.196,31.314C263.12,302.129,262.782,303.01,261.88,303.208z M333.207,287.247
				l-54.451,12.182c-0.768,0.176-1.388-0.219-1.564-0.987l-18.872-84.355c-0.169-0.769,0.225-1.389,0.994-1.565l54.444-12.182
				c0.768-0.17,1.389,0.225,1.565,0.994l2.46,10.99c0.169,0.769-0.226,1.389-0.994,1.558l-39.88,8.926
				c-0.507,0.112-0.705,0.423-0.592,0.944l4.744,21.212c0.12,0.508,0.43,0.712,0.938,0.599l33.612-7.522
				c0.775-0.169,1.396,0.226,1.572,0.987l2.46,10.998c0.169,0.761-0.225,1.389-1.001,1.558l-33.612,7.522
				c-0.508,0.113-0.705,0.43-0.592,0.938l4.92,21.98c0.113,0.515,0.423,0.719,0.938,0.606l39.88-8.925
				c0.762-0.169,1.389,0.226,1.558,0.988l2.46,10.997C334.364,286.458,333.969,287.078,333.207,287.247z M406.058,270.948
				l-54.451,12.182c-0.761,0.169-1.388-0.219-1.558-0.987l-18.871-84.356c-0.176-0.768,0.218-1.395,0.987-1.565l54.452-12.182
				c0.768-0.169,1.388,0.226,1.558,0.994l2.46,10.991c0.169,0.768-0.226,1.389-0.987,1.558l-39.88,8.924
				c-0.514,0.113-0.712,0.423-0.599,0.945l4.752,21.213c0.113,0.507,0.423,0.712,0.937,0.599l33.613-7.522
				c0.768-0.169,1.395,0.218,1.565,0.987l2.46,10.998c0.169,0.761-0.225,1.388-0.994,1.558l-33.612,7.522
				c-0.515,0.112-0.712,0.43-0.599,0.938l4.92,21.981c0.113,0.514,0.423,0.719,0.945,0.606l39.872-8.925
				c0.768-0.169,1.389,0.226,1.565,0.987l2.453,10.998C407.221,270.159,406.827,270.78,406.058,270.948z"/>
		</g>
</svg>
)

export default FreeIcon