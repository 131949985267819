import React from 'react';
import PropTypes from 'prop-types'


const VideoPlayer = React.memo((props) => {
    const {src, className, ...other} = props
    return (
        <video 
            src={src}
            className={className}
            controls
            controlsList="nodownload"
            onContextMenu={e => e.preventDefault()}
            {...other}
        />
    )
})

VideoPlayer.propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default VideoPlayer