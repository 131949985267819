import React, { useEffect, useImperativeHandle, useState, useMemo } from 'react'
import Input from '@material-ui/core/Input'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import BaseComponent from './BaseComponent'

const useStyles = makeStyles({
    root: {
        fontSize: 15,
        lineHeight: '42px',
        color: '#3F3F3F',
        textTransform: 'none',
        display: 'flex',
    },
    index: {
        marginRight: 10,
        fontWeight: 600,
    },
    input: {
        fontSize: 15,
        fontWeight: 700,
    },
    valid: {
        color: 'green',
    },
    invalid: {
        color: 'red',
    }
})


const Template = props => {
    const classes = useStyles()
    const {exercise, showResult, result, setResult} = props
    const {template, params} = exercise

    const handleInputChange = (arg, value, answers) => {
        if (!showResult) {
            value = value.replaceAll(/‘|’/g, '\'')
            setResult({...result, [arg]: {value, correct: answers.map(a => a.toLowerCase()).includes(value.toLowerCase().trim())}})
        }
    }

    return (
        <div className={classes.root}>
            <span className={classes.index}>{exercise.position}.</span>
            <div>
                {template.split('*').map((part, i) => {
                    if (i % 2 === 0) {
                        let lines = part.split('\\n')
                        return lines.map((v, i) => (
                            <><span key={i}>{v}</span>{(i != lines.length - 1) && <br/>}</>
                        ))
                    }
                    else {
                        const arg = part
                        const {align, answers} = params[arg]
                        const className = result[arg]?.correct ? classes.valid : classes.invalid
                        return (
                            <Input 
                                key={i}
                                inputProps={{
                                    size: 4 + (result[arg]?.value.length ?? 0),
                                    style: {textAlign: align},
                                    className: clsx(classes.input, showResult && className)
                                }}
                                value={result[arg]?.value ?? ''}
                                onChange={e => handleInputChange(arg, e.target.value, answers)}
                                disabled={showResult}
                            />
                        )
                    }
                })}
            </div>
        </div>
    )
}

const ExerciseFillTheGaps = ({lessonID, exercise, onSaveProgress}) => {

    const exercises = useMemo(() => exercise.list.slice().sort((a, b) => a.position - b.position), [exercise])
    const [results, setResults] = React.useState({})
    const [pageIndex, setPageIndex] = React.useState(0)
    const [showResult, setShowResult] = React.useState(false)
    const [correctAnswersCount, setCorrectAnswersCount] = React.useState(0)
    
    let pageStep = 5

    if (exercises.length < 5) {
        pageStep = 1
    }

    useEffect(() => {
        handleReset()
    }, [lessonID])

    const handleSetResult = (position, result) => {
        setResults({...results, [position]: result})
    }

    const handleCheck = () => {
        let count = 0
        for(const position in results) {
            const answers = results[position]
            let correct = true
            for(const arg in answers) {
                if (!answers[arg].correct) {
                    correct = false
                }
            }
            if (correct) {
                count++
            }
        }
        setCorrectAnswersCount(count)
        setShowResult(true)

        if (onSaveProgress) {
            let progress = count * 100 / exercises.length
            onSaveProgress(progress)
        }
    }
    const handleReset = () => {
        setShowResult(false)
        setResults({})
        setCorrectAnswersCount(0)
        setPageIndex(0)
    }

    if (exercises.length === 0) {
        return null
    }

    return (
        <BaseComponent
            title={`Exercise ${exercise.position}`}
            subtitle={exercise.title}
            currentPage={pageIndex}
            pagesCount={exercise.list.length}
            pagingStep={pageStep}
            onPagesSelected={index => setPageIndex(index)}
            correctAnswers={correctAnswersCount}
            totalQuestions={exercise.list.length}
            onCheck={handleCheck}
            onReset={handleReset}
            check={showResult}
            showResult={showResult}
            checkDisabled={showResult || Object.keys(results).length === 0}
            resetDisabled={!showResult}
        >
            {exercises.slice(pageIndex, pageIndex + pageStep).map((e, i) => (
                    <Template 
                        index={i}
                        key={e.id} 
                        exercise={e} 
                        showResult={showResult} 
                        result={results[e.position] ?? {}}
                        setResult={result => handleSetResult(e.position, result)}
                    />
                )
            )}
        </BaseComponent>
    )
}


export default ExerciseFillTheGaps