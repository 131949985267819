import React, {useState} from 'react';
import {selectUserStateStatus, selectUserStateError} from '../../redux/userStateSlice'
import {closeAuthForm, showSignUp} from '../../redux/appStateSlice'
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux'
import {ServerError  } from "../../utils/Error";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://edvi.uz/">
        EDVI.UZ
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  main:{
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons:{
    marginTop: 16,
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#0a6186',
      color: 'white'
    }
  },
  logo: {
    width: 66,
    height: 57
  },
}));

const Congratulations = (props) => {
  const {close} = props;
  const classes = useStyles();
  const dispatch = useDispatch()
  const authStatus = useSelector(selectUserStateStatus)
  
  const pending = authStatus === 'pending'
  
  const handleClose = () => {
      dispatch(closeAuthForm())
  }

  return (
    <Container className={classes.main}  component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
      <img className={classes.logo} src="/images/logo.png" alt="logo" />
        <Typography style={{marginTop: 24}} component="h1" variant="h5">
        Tabriklaymiz!
        </Typography>
        <div style={{marginTop: 24}}>
        Siz ro'yhatdan muvaffaqiyatli o'tdingiz.
        </div>
      </div>
      <Button
            onClick={handleClose}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={pending}
          >
            OK
          </Button>
    </Container>
  );
}

export default Congratulations