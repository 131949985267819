import axios from 'axios'
import parseResponse from '../utils/parseResponse'
import baseURL from './baseurl'

export const getSubscription = async (accessToken, courseID, planID) => {
    return parseResponse(await axios.get(`${baseURL}/subscription?course=${courseID}'&plan=${planID}`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}

export const getUserProfile = async (accessToken) => {
    return parseResponse(await axios.get(`${baseURL}/my-profile`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}

export const setUserProfile = async (accessToken, profile) => {
    return parseResponse(await axios.post(`${baseURL}/my-profile`, profile, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}

export const getUserProgress = async (accessToken) => {
    return parseResponse(await axios.get(`${baseURL}/my-progress`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}

export const setUserProgress = async (accessToken, progress) => {
    return parseResponse(await axios.post(`${baseURL}/my-progress`, progress, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}