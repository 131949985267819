import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import getStarsForProgress from "../../../../utils/getStarsForProgress";
import Circle from "./Circle";

import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentLessonID, setCurrentLessonID } from "../../../../redux/appStateSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 174,
    // height: 300,
    borderRadius: 24,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "0px 7px 17px rgba(0, 0, 0, 0.15)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 900px)": {
      border: 'none',
      boxShadow: 'none',
      width: "100%",
      flexDirection: "unset",
    },
  },
  rightSection:{
    textAlign: 'center',
    '@media (max-width: 900)':{
      marginLeft: 15,
    }
  },
  label: {
    marginTop: 8,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "18px",
    textAlign: "center",
  },
  rating: {
    marginTop: 10,
  },
  buttons: {
    marginTop: 10,
    marginBottom: 21,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonLabel: {
    fontSize: 9,
    fontWeight: 600,
    textTransform: "none",
    "@media (max-width:600px)":{
      fontSize: 11
    }
  },
  buttonRoot: {
    maxWidth: 68,
    minWidth: 68,
    height: 33,
    padding: 0,
    "@media (max-width:900px)":{
      maxWidth: 80,
      minWidth: 80,
    }
  },
  buttonOutlined:{
    border: '1px solid #178FC2'
  },
  progressDescription: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "24px",
    marginTop: 4,
    letterSpacing:"0.1em",
    '@media (max-width:600px)':{
      fontSize: 15
    }
  },
}));

const ExerciseProgress = ({
  className,
  correct,
  total,
  onCheck,
  onReset,
  resetDisabled,
  checkDisabled,
  showResult,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let progress = Math.floor((correct * 100) / total);
  let starsCount = getStarsForProgress(progress);
  let themeColor = theme.palette.primary.main;
  let descriptionColor = theme.palette.primary.main;
  let progressDescription = null;
  if (showResult) {
    if (starsCount === 0) {
      themeColor = "#E95C5C";
      descriptionColor = "#E95C5C";
      progressDescription = "Iltimos, qayta urinib ko'ring!";
    } else if (starsCount == 1) {
      descriptionColor = "#23B443";
      progressDescription = "Yomon emas!";
    } else if (starsCount == 2) {
      descriptionColor = "#23B443";
      progressDescription = "Yaxshi natija!";
    } else {
      descriptionColor = "#23B443";
      progressDescription = "Ajoyib natija!";
    }
  }
  const dispatch = useDispatch()
  const currentLessonID = useSelector(selectCurrentLessonID)
  const onCurrentLessonChanged = newLessonID => dispatch(setCurrentLessonID(newLessonID))
  return (
    <div className={clsx(classes.root, className)}>
      <Circle progress={progress} color={themeColor} />
      <div className={classes.rightSection}>
        <Typography className={classes.label} style={{ color: themeColor }}>
        To'g'ri:
          <br />
          {correct}/{total}
        </Typography>

        <Rating
          max={3}
          value={starsCount}
          size="large"
          readOnly
          className={classes.rating}
        />

        <Typography
          className={classes.progressDescription}
          style={{ color: descriptionColor }}
        >
          {progressDescription}
        </Typography>

        <div className={classes.buttons}>
          {
            checkDisabled && <Button
            variant="outlined"
            color="primary"
            classes={{
              label: classes.buttonLabel,
              root: classes.buttonRoot,
              
            }}
            onClick={onCheck}
            disabled
            style={{marginRight:8}}
          >
            Tekshirish
          </Button>
          }
          {
            !checkDisabled && <Button
            variant="outlined"
            color="primary"
            classes={{
              label: classes.buttonLabel,
              root: classes.buttonRoot,
              outlinedPrimary: classes.buttonOutlined,
            }}
            onClick={onCheck}
            style={{marginRight:8}}
          >
            Tekshirish
          </Button> 
          }
          
          <Button
            variant="outlined"
            color="primary"
            classes={{
              label: classes.buttonLabel,
              root: classes.buttonRoot,
              outlinedPrimary: classes.buttonOutlined,
            }}
            onClick={onReset}
            disabled={resetDisabled}
          >
            Qayta ishlash
          </Button>
          
        </div>
        {/* <Button
            variant="outlined"
            color="primary"
            classes={{
              label: classes.buttonLabel,
              root: classes.buttonRoot,
              outlinedPrimary: classes.buttonOutlined,
            }}
            onClick={() => onCurrentLessonChanged(currentLessonID + 1)}
          >
            Keyingi darslik
          </Button> */}
      </div>
    </div>
  );
};

ExerciseProgress.propTypes = {
  className: PropTypes.string,
  correct: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onCheck: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  resetDisabled: PropTypes.bool.isRequired,
  checkDisabled: PropTypes.bool.isRequired,
  showResult: PropTypes.bool.isRequired,
};

export default ExerciseProgress;
