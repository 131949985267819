const People = () => (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.89659 4.83645H3.44698C2.83343 4.81597 2.22186 4.91216 1.65024 5.11904C1.07862 5.32592 0.559134 5.63908 0.124029 6.03909L0 6.17272V10.1243H2.1085V7.88124L2.39273 7.58535L2.52193 7.44695C3.19475 6.80864 4.03239 6.33894 4.95601 6.08204C4.49358 5.75711 4.12888 5.32831 3.89659 4.83645Z" fill="#178FC2"/>
        <path d="M13.8915 6.02465C13.4564 5.62464 12.9369 5.31148 12.3653 5.1046C11.7937 4.89771 11.1821 4.80153 10.5686 4.82201C10.3804 4.8225 10.1924 4.83206 10.0053 4.85064C9.76862 5.31208 9.41387 5.71339 8.97168 6.01988C9.9577 6.27179 10.8509 6.7667 11.5556 7.4516L11.6848 7.58522L11.9639 7.88111V10.1289H14V6.15828L13.8915 6.02465Z" fill="#178FC2"/>
        <path d="M3.43163 3.90587H3.59183C3.5174 3.31564 3.62954 2.71813 3.91474 2.18546C4.19993 1.65279 4.64598 1.20774 5.19905 0.904027C4.99857 0.621191 4.72198 0.391284 4.39637 0.23682C4.07077 0.0823566 3.7073 0.00863173 3.34157 0.0228646C2.97584 0.0370974 2.62038 0.1388 2.31 0.318014C1.99962 0.497228 1.74496 0.747808 1.57096 1.04522C1.39695 1.34263 1.30957 1.67667 1.31737 2.01464C1.32517 2.3526 1.42789 2.6829 1.61545 2.9732C1.80302 3.26349 2.06901 3.50382 2.38738 3.67066C2.70574 3.8375 3.06557 3.92513 3.43163 3.92496V3.90587Z" fill="#178FC2"/>
        <path d="M10.3202 3.5479C10.3265 3.65758 10.3265 3.7675 10.3202 3.87719C10.4194 3.89171 10.5196 3.89968 10.62 3.90105H10.7182C11.0826 3.88311 11.4358 3.77832 11.7434 3.5969C12.051 3.41548 12.3024 3.1636 12.4733 2.86578C12.6442 2.56797 12.7286 2.23438 12.7185 1.89747C12.7083 1.56057 12.6038 1.23184 12.4152 0.94329C12.2266 0.65474 11.9603 0.416201 11.6423 0.250897C11.3242 0.0855937 10.9653 -0.000841668 10.6003 6.17778e-06C10.2353 0.000854024 9.87682 0.0889563 9.55967 0.255736C9.24252 0.422515 8.97754 0.662288 8.79053 0.951711C9.25826 1.23373 9.64288 1.61856 9.90986 2.07168C10.1768 2.5248 10.3178 3.03204 10.3202 3.5479Z" fill="#178FC2"/>
        <path d="M6.92968 5.68595C8.20548 5.68595 9.23973 4.73086 9.23973 3.55269C9.23973 2.37452 8.20548 1.41943 6.92968 1.41943C5.65387 1.41943 4.61963 2.37452 4.61963 3.55269C4.61963 4.73086 5.65387 5.68595 6.92968 5.68595Z" fill="#178FC2"/>
        <path d="M7.05455 6.82183C6.37964 6.79666 5.7062 6.89785 5.07475 7.11929C4.44329 7.34073 3.86687 7.67786 3.38017 8.11038L3.25098 8.244V11.2649C3.25299 11.3633 3.27598 11.4604 3.31863 11.5506C3.36128 11.6408 3.42275 11.7223 3.49954 11.7906C3.57632 11.8588 3.66692 11.9125 3.76614 11.9484C3.86537 11.9843 3.97129 12.0018 4.07784 11.9999H10.0157C10.1223 12.0018 10.2282 11.9843 10.3274 11.9484C10.4267 11.9125 10.5173 11.8588 10.594 11.7906C10.6708 11.7223 10.7323 11.6408 10.775 11.5506C10.8176 11.4604 10.8406 11.3633 10.8426 11.2649V8.25355L10.7186 8.11038C10.235 7.67653 9.66063 7.33845 9.03057 7.11688C8.40052 6.89531 7.72811 6.7949 7.05455 6.82183Z" fill="#178FC2"/>
    </svg>

)

export default People