import React, { useCallback } from 'react'
import { hideLogIn } from '../../redux/appStateSlice'
import { Button, Container, Paper, TextField, Typography, withStyles } from '@material-ui/core'
import LoginWithTelegram from './TelegramLoginWidget'
import TelegramLoginWidget from './TelegramLoginWidget'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { asyncLogInWithTelegram } from '../../redux/userStateSlice'

const LogIn = withStyles({
	container: {
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		backgroundColor: '#dce6e8',
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		backgroundColor: 'white',
	},
	closeIcon: {
		marginTop: 10,
		marginRight: 10,
		alignSelf: 'flex-end',
		cursor: 'pointer',
		color: '#444',
		'&:hover':{
		  backgroundColor: '#0a6186',
		  color: 'white'
		}
	},
	title: {
		marginBottom: 22,
		color: '#444',
	},
	body: {
		padding: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	loginButton: {
		margin: '6px 20px',
		alignSelf: 'center',
	},
	form: {
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		maxWidth: 300,
	},
	footer: {
		backgroundColor: 'white',
		padding: 20,
	}
})(props => {
	
	const classes = props.classes
	const dispatch = useDispatch()

	const onDataAuth = useCallback((user) => {
		console.log('tg', user)
		dispatch(asyncLogInWithTelegram(user))
	})

	const onClose = () => {
		dispatch(hideLogIn())
	}

	return (
		<Container maxWidth="xs" className={classes.container}>
			<div className={classes.header}>
				<CloseIcon onClick={onClose} className={classes.closeIcon} />
				<Typography variant='h5' align='center' className={classes.title}>
					Ro'yxatdan o'tish
				</Typography>
			</div>
			<div className={classes.body}>
				<TelegramLoginWidget className={classes.loginButton} dataTelegramLogin='edvi_platform_bot' onDataAuth={onDataAuth} />
				<Typography align='center'>or</Typography>
				<form className={classes.form}>
					<TextField
						label="Username"
					/>
					<TextField
						label="Password"
					/>
					<Button>
						LOG IN
					</Button>
				</form>
			</div>
			{/* <div className={classes.footer}>
				<Typography>Forgot password?</Typography>
			</div> */}
		</Container>
	)
})



export default LogIn