import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { selectLessonById, selectUnitById, selectVideoById } from "../../redux/coursesSlice";
import Button from '@material-ui/core/Button';
import VideoPlayer from './VideoPlayer'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10
    },
    noVideo: {
        width: '100%',
        minHeight: '480px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        color: 'white',
    },
    videoPlayer: {
        width: '100%',
        borderTop: '5px solid black',
        marginTop: 10
        // minHeight: '480px',
    },
    footer: {
        height: '30px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 20
    },
    button: {
        zIndex: 1000,
        // borderRadius: 0,
        borderColor: '#d8e5f1'
    },
    selected: {
        backgroundColor: '#d8e5f1'
    },
    disableTextTransform:{
        textTransform:'none'
    }
})

const LessonVideo = (props) => {

    const classes = useStyles()
    const {lessonID, className} = props
    
    const lesson = useSelector(state => selectLessonById(state, lessonID))
    const unit = useSelector(state => selectUnitById(state, lesson ? lesson['unit_id'] : 0))
    const videoIDs = lesson ? lesson.videos : []
    const videos = useSelector(state => videoIDs.map(videoID => selectVideoById(state, videoID)))
    const [currentVideoIdx, setCurrentVideoIdx] = useState(0)
    useEffect(() => {
        setCurrentVideoIdx(0)
    }, [lessonID])

    if (videos.length === 0) {
        return (
            <div className={classes.noVideo}>
                <h1>No video available</h1>
            </div>
        )
    }

    const currentVideo = videos[currentVideoIdx]
    let src = null
    if (currentVideo) {
        // src=`https://drive.google.com/uc?id=${currentVideo.cloud_path}`
        src=`https://files.edvi.uz/unit_${unit.position}/${currentVideo.filename}`
    }
            
    return (
        <div className={clsx(classes.root, className)}>
            <VideoPlayer 
                src={src}
                className={classes.videoPlayer}
                controls
            />
            <div className={classes.footer}>
                {videos.length > 1 && videos.map((v, idx) => (
                    <Button 
                        classes={{label: classes.disableTextTransform}}
                        key={v.id} 
                        variant="outlined" 
                        onClick={() => setCurrentVideoIdx(idx)}
                        className={clsx(classes.button, idx === currentVideoIdx && classes.selected)}
                    >
                       Video {v.position}
                    </Button>
                ))}
            </div>
        </div>
    )
}

LessonVideo.propTypes = {
    lessonID: PropTypes.number,
    className: PropTypes.string
}

export default LessonVideo