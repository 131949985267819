import axios from 'axios'
import parseResponse from '../utils/parseResponse'
import baseURL from './baseurl'

export const signIn = async credentials => {
    return parseResponse(await axios.post(`${baseURL}/sign-in`, credentials))
}

export const signUp = async credentials => {
    return parseResponse(await axios.post(`${baseURL}/sign-up`, credentials))
}

export const logInWithTelegram = async tgUser => {
    return parseResponse(await axios.post(`${baseURL}/login/telegram`, tgUser))
}