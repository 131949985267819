import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useDispatch, useSelector } from 'react-redux';
import { asyncSignUp, selectUserStateError, selectUserStateStatus } from '../../redux/userStateSlice';
import { showSignIn } from '../../redux/appStateSlice'
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0
    },
  },
})(TextField);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
      EDVI.UZ
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  main:{
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: '25px'
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons:{
    marginTop: 16,
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#0a6186',
      color: 'white'
    }
  },
  textField:{
    padding: 0
  }
}));

const SignUp = ({close}) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('+998 ')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [localError, setLocalError] = useState(null)

  const authStatus = useSelector(selectUserStateStatus)
  const authError = useSelector(selectUserStateError)
  const pending = authStatus === 'pending'

  const onChangePhoneNumber = (e) => {
  
    /**
     * @type {string}
     */
    let value = e.target.value

    if (!value.startsWith('+998')) {
      value = `+998 ${value} `
    }

    const re = /\+998 \d{0,9}/g;
    let matched = value.match(re)
    if (matched && matched.length > 0) {
      setUsername(matched[0])
    }
    
}

  const handleSignIn = e => {
      e.preventDefault()
      dispatch(showSignIn())
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (username.length === 0) {
      setLocalError('Username is required')
    }
    else if (password.length === 0) {
      setLocalError('Password is required')
    }
    else if (firstName.length === 0) {
      setLocalError('First name is required')
    }
    else if (lastName.length === 0) {
      setLocalError('Last name is required')
    }
    else {
      dispatch(asyncSignUp(email, username.replace(' ', ''), password, firstName, lastName))
    }
  }

  let errorText = null
  if (authError) {
    errorText = authError.message
  }
  else if (localError) {
    errorText = localError
  }

  return (
    <Container className={classes.main} component="main" maxWidth="xs">
      <CssBaseline />
      <CloseIcon onClick={close} className={classes.icons}/>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
        Ro'yxatdan o'tish
        </Typography>
        {errorText && (
          <Typography variant="subtitle1" color="secondary">
            {errorText}
          </Typography>
        )}
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              
              <Typography>Ismingiz</Typography>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                inputProps={{
                  inp: classes.textField 
                }}
                fullWidth
                id="firstName"
                autoFocus
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                disabled={pending}
              />
            </Grid>
            <Grid item xs={12}>
            <Typography>Familiyangiz</Typography>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                disabled={pending}
              />
            </Grid>
            <Grid item xs={12}>
            <Typography>Telefon raqamingiz</Typography>
              <TextField
                
                variant="outlined"
                required
                fullWidth
                id="pusername"
                name="pusername"
                value={username}
                onChange={onChangePhoneNumber}
                disabled={pending}
                type="tel"
                inputProps={{
                  pattern: "\+998 \d{2} \d{3}-\d{4}"
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Elektron pochtangiz</Typography>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={pending}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Parol</Typography>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                type="password"
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                autoComplete="current-password"
                disabled={pending}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={pending}
          >
            Ro`yxatdan o`tish
          </Button>
          <Grid  container style={{textAlign:"center", marginBottom:"10px"}}>
            <Grid xs={12} item>
              <Link href="#" variant="body2" onClick={handleSignIn}>
              Akkauntingiz bormi? Kirish ga bosing
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default SignUp