import {createSlice} from '@reduxjs/toolkit'
import * as cache from '../utils/appStateCache'

const slice = createSlice({
    name: 'appState',
    initialState: {
        authForm: null,
        lessonID: cache.getLessionID(),
        lastRoute: cache.getLastRoute(),
        buyCourse: null,
        logIn: false,
    },
    reducers: {
        showLogIn: (state) => {
            state.logIn = true
        },
        hideLogIn: (state) => {
            state.logIn = false
        },
        showSignUp: (state) => {
            state.authForm = 'signup'
        },
        showSignIn: (state) => {
            state.authForm = 'signin'
        },
        closeAuthForm: (state) => {
            state.authForm = null
        },
        setLastRoute: (state, action) => {
            state.lastRoute = action.payload
            cache.setLastRoute(state.lastRoute)
        },
        setCurrentLessonID: (state, action) => {
            if (!isNaN(action.payload) || action.payload === null) {
                state.lessonID = action.payload
                cache.setLastLessonID(state.lessonID)
            }
        },
        openBuyCoursePage: {
            reducer(state, action) {
                state.buyCourse = action.payload.courseID
            },
            prepare(courseID) {
                return {
                    payload: {
                        courseID: courseID
                    }
                }
            }
        },
        closeBuyCoursePage: (state) => {
            state.buyCourse = null
        }

    }
})

export const {showSignIn, showSignUp, showLogIn, hideLogIn, closeAuthForm, setCurrentLessonID, setLastRoute, openBuyCoursePage, closeBuyCoursePage} = slice.actions
export const selectAuthFormState = state => state.appState.authForm
export const selectLastRoute = state => state.appState.lastRoute
export const selectCurrentLessonID = state => state.appState.lessonID
export const selectBuyCourseID = state => state.appState.buyCourse
export const selectShowLogIn = state => state.appState.logIn
export default slice.reducer