import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import  Hidden  from '@material-ui/core/Hidden'

const Footer = withStyles({
    root: {
        backgroundColor: '#FAFAFA',
        border: '1px solid #E4E4E4',
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: '10px 0'
    },
    bottom: {
        backgroundColor: '#EBEBEB',
        textAlign: 'center',
        fontSize: 12,
        paddingTop: 15,
        paddingBottom: 10,
    },
    links: {
        display: 'flex',
        gap: 20,
    },
    link: {
        textDecoration: 'underline'
    },
    icons: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 10,
    },
    logo: {
        width: 60,
        height: 52
    },
    hidden: {},
    '@media (max-width: 400px)': {
        hidden: {
            display: 'none'
        },
        links: {
            display: 'flex',
            gap: 0,
            flexDirection: 'column',
            '& > *': {
                marginTop: 10,
            }
        },
        logo: {
            
        }
    }
})
(({classes}) => (
    <div className={classes.root}>
        <div className={classes.top}>
            <img className={classes.logo} src="/images/logo.png" alt="logo"/>
            <div className={classes.links}>
                
            </div>
            <div>
                <Typography>Bizni kuzating</Typography>
                <div className={classes.icons}>
                    <a href="https://www.instagram.com/edvi.uz/">
                    <img src="/icons/instagram-filled.svg" alt="instagram icon"/>
                    </a>
                    <a href="https://www.facebook.com/edvi.uz">
                    <img src="/icons/facebook-filled.svg" alt="facebook icon"/>
                    </a>
                    <a href="https://t.me/edvi_beginner">
                    <img src="/icons/telegram-filled.svg" alt="telegram icon"/>
                    </a>
                </div>
            </div>
        </div>
        <Typography className={classes.bottom}>
            © Copyright 2020. All rigths reserved
        </Typography>
    </div>
))

export default Footer