export const getCachedToken = () => {
    return window.localStorage.getItem('token')
}

export const getCachedUserProfile = () => {
    try {
        return JSON.parse(window.localStorage.getItem('userProfile'))
    }
    catch(e) {
        return null
    }
}

export const getCachedUserProgres = () => {
    try {
        return JSON.parse(window.localStorage.getItem('userProgress'))
    }
    catch(e) {
        return null
    }
}

export const setUserState = (token, profile, progress) => {
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('userProfile', JSON.stringify(profile))
    window.localStorage.setItem('userProgress', JSON.stringify(progress))
}

export const resetCache = () => {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('userProfile')
    window.localStorage.removeItem('userProgress')
}