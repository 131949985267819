export class NetworkError extends Error {
    constructor(code, text) {
        super(text)
        this.code = `${code}`
        this.name = NetworkError.Name
    }
    static Name = 'NetworkError'
}

export class ServerError extends Error {
    static Code =  {
        ValidationError: '100',
        UsernameExists: '101',
        EmailAlreadyRegistered: '102',
        InvalidEmail: '103',
        SystemError: '104',
        UnknownError: '105',
        InvalidActivationLink: '106',
        InvalidLoginOrPassword: '107',
        UserNotFound: '108'
    }

    constructor(code, message) {
        super(message)
        this.code = `${code}`
        this.name = ServerError.Name
    }
    static Name = 'ServerError'
}

