import React, { useEffect, useRef } from 'react'



const TelegramLoginWidget = ({className, dataTelegramLogin, onDataAuth, children}) => {
	
	const ref = useRef(null)

	useEffect(() => {

		window._onTelegramAuth = user => {
			onDataAuth(user)
		}

		const script = document.createElement('script')
		script.src = 'https://telegram.org/js/telegram-widget.js?19'
		script.async = true
		script.setAttribute('data-telegram-login', dataTelegramLogin)
		script.setAttribute('data-size', 'large')
		script.setAttribute('data-onauth', '_onTelegramAuth(user)')
		script.setAttribute('data-request-access', 'write')
		script.setAttribute('data-radius', 4)
		ref.current.appendChild(script)
		const current = ref.current
		return () => {
			current.removeChild(script)
		}


	}, [dataTelegramLogin, onDataAuth])

	return <div className={className} ref={ref}>{children}</div>
}

export default TelegramLoginWidget