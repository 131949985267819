import React, { useEffect, useRef} from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectCourseById, selectCourseStatus, fetchCourseFull, selectLessonById, selectUnitById } from "../../redux/coursesSlice";
import { setCurrentLessonID, selectCurrentLessonID} from '../../redux/appStateSlice'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import CourseProgress from './CourseProgress/CourseProgress'
import UnitsList from './UnitsList'
import ExercisesList from './Exercises/ExercisesList'
import LessonVideo from './LessonVideo'
import {YouWillLearn, Description, AboutAuthor} from '../CourseOverview/CourseOverview'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Hidden  from '@material-ui/core/Hidden';
import Typography  from '@material-ui/core/Typography';


const OverViewTab = () => {
    const classes = useStyles()
    const author = {
        "fullName": "Farukh Korchiyev",
        "photoUrl": "/images/teacher2.jpg",
        "profession": "English teacher",
        "studentsCount": 1000,
        "coursesCount": 1,
        "socialNetworks": [
            {
                "id": 1,
                "type": "instagram",
                "username": "@edvi.uz"
            }
        ],
        "about": "Farukh Korchiyev is an English teacher. Has 7 years of work experience. It has trained high level students from primary education to IELTS\n\nIt is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here"
    }
    
    return (
        <div className={classes.mainOver}>
            <YouWillLearn/>
            <Description/>
            <AboutAuthor author={author}/>
        </div>
    )
}


const useStyles = makeStyles({
    root: {
        display: 'flex',
        backgroundColor: 'white',
    },
    loadingRoot: {
        width: '100%',
        height: '100vh',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    main: {
        flex: '1 1 880px',
        marginBottom: 14,
        backgroundColor: 'white',
        '@media (max-width: 600px)':{
            marginTop: 7,
        }
    },
    mainOver:{
        margin: '0',
        '@media (max-width: 600px)':{
            margin: '0 16px'
        }
    },
    section: {
        padding: '0 34px 0 52px',
        '@media (max-width:600px)':{
            padding: '0'
        }
    },
    rightBlock: {
        width: 320,
    },
    videoContainer: {
        width: '100%'
    },
    title:{
        fontWeight: 'bold',
        marginLeft: 15,
        fontFamily:"Roboto",
        letterSpacing: '0.035em',
        lineHeight: '133.69%'
    },
    tabPlan:{
        display: 'none',
    },
    '@media (max-width: 960px)':{
        tabPlan:{
            display: 'block'
        }
    },
    disableTextTransform: {
        textTransform: "none",
        padding: 0,
        fontSize: 17,
        '@media (max-width: 600px)':{
            fontSize: 15,
            marginLeft: 20,
        }
    },
    lessonTitle: {
        marginTop: 40,
        textAlign: 'center',
        fontWeight: 'bold',

    }
})

const CustomTabs = withStyles({indicator: {backgroundColor: 'black'}})(Tabs)

const TabPanel = props => {
    const { children, value, index, className} = props;
    
    if (value !== index) {
        return null
    }
    return (
        <div className={className}>
            {children}
        </div>
    )
}

const Course = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const currentLessonID = useSelector(selectCurrentLessonID)
    const params = useParams()
    const courseID = parseInt(params['courseID'])
    const courseStatus = useSelector(state => selectCourseStatus(state, courseID))
    const course = useSelector(state => selectCourseById(state, courseID))
    const [tabIndex, setTabIndex] = React.useState(2)
    const onTabSelected = (e, newValue) => setTabIndex(newValue)
    const onCurrentLessonChanged = newLessonID => dispatch(setCurrentLessonID(newLessonID))
    const lesson = useSelector(state => selectLessonById(state, currentLessonID))
    // const unit = useSelector(state => selectUnitById(state, lesson.unit_id))
    // const unitTitle = unit.position > 0 ? `Unit: ${unit.position}. ${unit.title}`: `Unit : ${unit.title}`
    const myRef = useRef()
    const executeScroll = () => {
        window.scrollTo({
            behavior: "smooth",
            top: myRef.current.offsetTop
        });
    }
    useEffect(() => {
        setTabIndex(2)
    }, [currentLessonID])
    useEffect(() => {
        if(myRef.current !== undefined){
            executeScroll()
        }
        
        if(currentLessonID === null){
            onCurrentLessonChanged(3)
        }
    }, [])
    useEffect(() => {
        if (courseStatus === 'idle') {
            dispatch(fetchCourseFull(courseID))
        }
    }, [courseID, courseStatus, dispatch])

    if (!course || courseStatus !== 'fulfilled') {
        return (
            <div className={classes.loadingRoot}>
                <CircularProgress size={100}/>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <main className={classes.main}>
                <Typography ref={myRef} className={classes.title}>
                    {/* {unitTitle}. <br />  */}
                    {/* Lesson: {lesson.title} */}
                </Typography>
                <CourseProgress  courseID={courseID}/>
                <div className={classes.section}>
                    {lesson && lesson.videos.length > 0 ? (<LessonVideo lessonID={currentLessonID} className={classes.videoContainer}/>) :
                        (<Typography variant="h4" className={classes.lessonTitle}>{lesson && lesson.title}</Typography>)}
                    <CustomTabs value={tabIndex} onChange={onTabSelected}>
                            <Tab classes={{ root: classes.disableTextTransform }} label="Ma'lumot" index={0}/>
                            <Tab classes={{ root: classes.disableTextTransform }} className={classes.tabPlan} label="Kurs rejasi"/>
                            <Tab classes={{ root: classes.disableTextTransform }} label="Mashqlar" onChange={onTabSelected} />
                            {/* <Tab label="Practice" disabled/> */}
                    </CustomTabs>
                    <Hidden mdUp>
                        <TabPanel value={tabIndex} index={1}>
                            <UnitsList 
                            courseID={courseID} 
                            className={classes.rightBlock}
                            selectedLessonID={currentLessonID}
                            onLessonSelected={onCurrentLessonChanged}
                            scrollTop={executeScroll}
                        />
                    </TabPanel>
                    </Hidden>
                    <TabPanel value={tabIndex} index={0}>
                        <OverViewTab/>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <ExercisesList lessonID={currentLessonID}/>
                    </TabPanel>
                </div>
            </main>
            <Hidden smDown>
                <UnitsList 
                    courseID={courseID} 
                    className={classes.rightBlock}
                    selectedLessonID={currentLessonID}
                    onLessonSelected={onCurrentLessonChanged}
                    scrollTop={executeScroll}
                />
            </Hidden>
        </div>
    )
}

export default Course