import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCourses, getCourseFull} from "../APIs/courses";
import { selectAccessToken } from './userStateSlice';
import axios from 'axios'

export const fetchCourses = createAsyncThunk('courses/fetchCourses', async () => {
    return await getCourses()
}, {
    condition: (_, thunkAPI) => {
        const status = selectLoadCoursesStatus(thunkAPI.getState())
        return status === 'idle'
    }
})

export const fetchCourseFull = createAsyncThunk('courses/fetchCourseFull', async(courseID, thunkAPI) => {
    return await getCourseFull(courseID, selectAccessToken(thunkAPI.getState()))
}, {
    condition: (courseID, thunkAPI) => {
        const status = selectCourseStatus(thunkAPI.getState(), courseID)
        return status === 'idle'
    }
})


const slice = createSlice({
    name: 'entities',
    initialState: {
        loadCoursesStatus: 'idle',
        loadCourseStatusById: {},
        courses: {
            allIds: [],
            byId: {},
        },
        units: {
            byId: [],
        },
        lessons: {
            byId: {}
        },
        exercises: {
            byId: {},
        },
        videos: {
            byId: {},
        },
    },
    reducers: {
        resetCoursesLoadStatus: (state) => {
            state.loadCourseStatusById = {}
        }
    },
    extraReducers: {
        [fetchCourses.pending]: (state) => {
            state.loadCoursesStatus = 'pending'
        },
        [fetchCourses.fulfilled]: (state, action) => {
            const courses = action.payload
            state.courses.allIds = courses.map(c => c.id)
            courses.forEach(c => {
                const old = state.courses.byId[c.id]
                if (!old) {
                    state.courses.byId[c.id] = c
                }
            })
            state.loadCoursesStatus = 'fulfilled'
        },
        [fetchCourses.rejected]: (state, action) => {
            state.loadCoursesStatus = 'rejected'
            console.error('[fetchCourses.rejected]', action.error)
        },
        [fetchCourseFull.pending]: (state, action) => {
            const courseID = action.meta.arg
            state.loadCourseStatusById[courseID] = 'pending'
        },
        [fetchCourseFull.fulfilled]: (state, action) => {
            const courseID = action.meta.arg
            state.loadCourseStatusById[courseID] = 'fulfilled'
            
            const {units, ...course} = action.payload

            //  Update course
            state.courses.byId[courseID] = {...course, ...{units: units.sort((u1, u2) => u1.position - u2.position).map(u => u.id)}}

            //  Update units, lessons, exercises, videos
            units.forEach(item => {

                const {lessons, ...unit} = item

                // Update unit
                state.units.byId[unit.id] = {...unit, ...{lessons: lessons.sort((l1, l2) => l1.position - l2.position).map(l => l.id)}}

                // Update lessons
                lessons.forEach(item => {
                    const {videos, exercises, ...lesson} = item

                    // Update lesson
                    state.lessons.byId[lesson.id] = {...lesson, ...{videos: videos.sort((v1, v2) => v1.position - v2.position).map(v => v.id), ...{exercises: exercises.map(e => e.id)}}}

                    // Update videos
                    videos.forEach(v => {
                        state.videos.byId[v.id] = v
                    })

                    // Update exercises
                    exercises.forEach(e => {
                        state.exercises.byId[e.id] = e
                    })
                })
            })
        }
    }

})

export const {resetCoursesLoadStatus} = slice.actions
export const selectLoadCoursesStatus = state => state.entities.loadCoursesStatus
export const selectCourseStatus = (state, courseID) => state.entities.loadCourseStatusById[courseID] ?? 'idle'
export const selectAllCoursesIds = state => state.entities.courses.allIds
export const selectCourseById = (state, courseID) => state.entities.courses.byId[courseID]
export const selectUnitById = (state, unitID) => state.entities.units.byId[unitID]
export const selectLessonById = (state, lessonID) => state.entities.lessons.byId[lessonID]
export const selectVideoById = (state, videoID) => state.entities.videos.byId[videoID]
export const selectExerciseById = (state, exerciseID) => state.entities.exercises.byId[exerciseID]
export default slice.reducer