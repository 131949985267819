export const setLastLessonID = lessonID => {
    window.localStorage.setItem('lastLessonID', lessonID)
}

export const setLastRoute = route => {
    window.localStorage.setItem('lastRoute', route)
}

export const getLastRoute = () => {
    return window.localStorage.getItem('lastRoute') ?? null
}

export const getLessionID = () => {
    const lessonID = parseInt(window.localStorage.getItem('lastLessonID'))
    if (isNaN(lessonID)) {
        return null
    }
    return lessonID
}

export const resetCache = () => {
    window.localStorage.removeItem('lastRoute')
    window.localStorage.removeItem('lastSessionID')
}