import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles({
    root: {
        width: props => props.size || 44,
        height: 44,
        borderRadius: props => props.size ? props.size / 2 : 22,
        backgroundColor: '#178FC2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 3,
        '@media (max-width: 600px)':{
            width: 55,
            height: 46,
            borderRadius: "50%",

        }
    },
    label: {
        color: 'white',
        fontSize: 15,
        lineHeight: '16px',
        fontWeight: 600,
    }
})


const CircularProgress = props => {
    const classes = useStyles(props)
    return (
        <div className={classes.root}>
            <Typography className={classes.label}>
                {Math.round(props.completed * 100 / props.total)}%
            </Typography>
        </div>
    )
}

CircularProgress.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    completed: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
}

export default CircularProgress