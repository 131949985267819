import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import ExerciseProgress from './ProgressCard/ExerciseProgress'
import Button from '@material-ui/core/Button'
import { Hidden } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 19,
        borderRadius: 10,
        display: 'flex',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
        padding: '36px 47px 22px 47px',
        gap: 20,
        '@media (max-width: 900px)': {
            flexWrap: 'wrap',
            padding: '22px 16px 25px'
        }
    },
    main: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    exercise: {
        flexGrow: 1,
        marginTop: 21,
    },
    progress: {
        flex: '0 0 auto',
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '22px',
    },
    subtitle: {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: '32x',
        color: '#1F1F1F'
    },
    footer: {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: '32px',
        color: '#A8A8A8',
        marginTop: 5
    },
    cursor: {
        cursor: 'pointer',
        '&:hover': {
        }
    },
    pageControl: {
        minWidth: 0,
        marginRight: 5,
        fontSize: 14
    },
    selectedLabel: {
        color: theme.palette.primary.main,
        fontWeight: 700
    }
}))


const BaseComponent = (props) => {
    const classes = useStyles()
    const {
        title, 
        subtitle, 
        children,
        pagesCount, 
        pagingStep, 
        currentPage, 
        onPagesSelected, 
        correctAnswers, 
        totalQuestions, 
        onCheck, 
        onReset,
        checkDisabled,
        resetDisabled,
        showResult,
    } = props

    let pages = []
    const count = Math.floor(pagesCount / pagingStep)
    for(let i = 0; i < count; ++i) {
        pages.push({start: i * pagingStep, length: pagingStep})
    }
    if (pagesCount % pagingStep >= 1) {
        pages.push({start: pagesCount * pagingStep, length: Math.floor(pagesCount % pagingStep)})
    }

    return (
        <div className={classes.root}>
            <main className={classes.main}>
                <header>
                    <Typography className={classes.title} variant="h1">
                        {title}
                    </Typography>
                    <Typography className={classes.subtitle} variant="subtitle1">
                        {subtitle}
                    </Typography>
                </header>
                
                <div className={classes.exercise}>
                    {children}
                </div>

                <footer className={classes.footer}>
                    Savollar: <Hidden smUp><br /></Hidden>

                    {pages.map(({start, length}) => (
                        <Button 
                            key={start}
                            classes={{
                                root: classes.pageControl,
                                label: clsx(start === currentPage && classes.selectedLabel)
                            }}
                            color="inherit"
                            onClick={() => {onPagesSelected(start, length)}}
                        >
                            {length > 1 ? `${start+1} - ${start + length}` : `${start + 1}`}
                        </Button>
                    ))}
                </footer>
            </main>
            <ExerciseProgress
                className={classes.progress}
                correct={correctAnswers}
                total={totalQuestions}
                onCheck={onCheck}
                onReset={onReset}
                checkDisabled={checkDisabled}
                resetDisabled={resetDisabled}
                showResult={showResult}
            />
        </div>
    )
}

BaseComponent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    pagesCount: PropTypes.number.isRequired,
    pagingStep: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPagesSelected: PropTypes.func.isRequired,
    correctAnswers: PropTypes.number.isRequired,
    totalQuestions: PropTypes.number.isRequired,
    onCheck: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    checkDisabled: PropTypes.bool.isRequired,
    resetDisabled: PropTypes.bool.isRequired,
    showResult: PropTypes.bool.isRequired,
}

export default BaseComponent