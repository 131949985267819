export default function getStarsForProgress(progress) {
    let starsCount = 0
    if (progress) {
        if (progress >= 70 && progress < 80) {
            starsCount = 1
        }
        else if (progress >= 80 && progress < 90) {
            starsCount = 2
        }
        else if (progress >= 90) {
            starsCount = 3
        }
    }
    return starsCount
}