import {configureStore} from '@reduxjs/toolkit'
import coursesSlice from './coursesSlice'
import userStateSlice from './userStateSlice'
import appStateSlice from './appStateSlice'

export default configureStore({
    reducer: {
        entities: coursesSlice,
        userState: userStateSlice,
        appState: appStateSlice,
    }
})