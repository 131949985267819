import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        minWidth: "280px",
        display: 'flex',
        flexDirection: 'column',
    },
    leftSection:{
        display: 'flex',
        justifyContent:'flex-end',
        paddingRight: 5,
        paddingLeft: 3,
        '@media (max-width:900px)':{
            justifyContent:'space-between'
        }
    },
    progressLabel: {
        alignSelf: 'flex-end',
        color: '#4B4B49',
        fontSize: 12,
        lineHeight: '12px',
        marginBottom: 2,
    },
    progressContainer: {
        height: 17,
        borderRadius: 70,
        backgroundColor: '#EBEBEB',
    },
    progress: {
        height: 17,
        borderRadius: 70,
        backgroundColor: '#178FC2',
        width: props => `${props.completed * 100 / props.total}%`,
    },
    completedLabel: {
        textAlign: 'right',
        fontSize: 10,
        lineHeight: '12px',
        color: 'white',
        fontWeight: 500,
        marginRight: 30
    },
    label:{
        fontSize: 12
    }
})

const LinearProgress = (props) => {

    const classes = useStyles(props)

    return (
        <div className={clsx(classes.root, props.className)}>
            <div className={classes.leftSection}>
                <Hidden mdUp>
                <Typography className={classes.label}>Sizning natijangiz</Typography>
                </Hidden>
                <Typography className={classes.progressLabel} variant="subtitle1">{props.completed}/{props.total}</Typography>
            </div>
            <div className={classes.progressContainer}>
                <div className={classes.progress}>
                </div>
            </div>
        </div>
    )
}

LinearProgress.propTypes = {
    className: PropTypes.string,
    completed: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
}

export default LinearProgress