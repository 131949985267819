import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from 'react-redux'
import {selectBuyCourseID, closeBuyCoursePage, showSignIn} from '../../redux/appStateSlice'
import {selectCourseById} from '../../redux/coursesSlice'
import {selectAccessToken, selectUserProfile} from '../../redux/userStateSlice'
import {makeStyles} from '@material-ui/core/styles'
import {getSubscription} from '../../APIs/userState'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import './click.css'
import ButtonBase from '@material-ui/core/ButtonBase'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    header: {
        fontWeight: 600,
        fontSize: 50,
    },
    dialogContent: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: '30px 20px 50px',
        '& > * + *': {
            marginLeft: 10
        },
    },
    closeIcon:{
        margin: 10,
        textAlign:'right',
        cursor: 'pointer',
    },

    divider: {
        maxWidth: 200,  
    },
    planCard: {
        minWidth: 400,
        minHeight: 300,
        display: 'flex',
        flexShrink: 1,
        flexDirection: 'column',
        boxShadow: '0px 9px 47px rgba(0, 0, 0, 0.09)',
        borderRadius: 30,
        marginBottom: 15,
    },
    planCardContent: {
        flex: '1 1',
    },
    planCardHeader: {
        fontWeight: 700,
        fontSize: 27,
    },
    planCardActions: {
        flexDirection: 'column'
    },
    planFeatureItem: {
        paddingTop: 5,
        paddingBottom: 0,
        alignItems: 'flex-start'
    },
    planFeatureItemIcon: {
        minWidth: 0,
        marginRight: 13,
        marginTop: 10
    },
    planFeatureItemText: {
        fontSize: 14,
        lineHeight: '20px',
        color: '#1D1D1D',
        fontWeight: 500,
    },
    planFeaturePrice: {
        marginTop: 40,
        marginBottom: 60, 
        fontWeight: 700,
        fontSize: 27,
        color: '#1D1D1D'
    },
    buyButton: {
        marginBottom: 30
    },
    buyButtonLabel: {
        margin: '14px 50px',
        fontWeight: 600,
        fontSize: 15,
        lineHeight: '17px',
        textTransform: 'none'
    },
    paymeLogo: {
        maxWidth: 100,
        margin: '10px',
    },
    paymentDialogContent: {
        display: 'flex',
        '& > * + *': {
            marginLeft: 20
        }
    }
})

const HeaderDivider = ({fill}) => (
    <div style={{
        width: 200,
        height: 5,
        margin: '0 auto',
        borderRadius: 3,
        overflow: 'hidden',
        backgroundColor: '#E1E1E1'
    }}>
        <div style={{
            backgroundColor: '#6EC645',
            height: '100%',
            width: `${fill}%`,
            maxWidth: 200,
        }}/>
    </div>
)

const GreenCircleIcon = () => (
    <span style={{
        backgroundColor: '#6EC645',
        width: 9,
        height: 9,
        borderRadius: 4.5,
        display: 'inline-block'
    }}/>
)

const StarIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.70799 11.9544C2.41849 12.1128 2.08999 11.8352 2.14849 11.4808L2.77099 7.69694L0.128744 5.01223C-0.118006 4.76104 0.0102439 4.30186 0.340993 4.25226L4.01448 3.69548L5.65248 0.233992C5.80023 -0.0779974 6.19998 -0.0779974 6.34773 0.233992L7.98572 3.69548L11.6592 4.25226C11.99 4.30186 12.1182 4.76104 11.8707 5.01223L9.22922 7.69694L9.85172 11.4808C9.91022 11.8352 9.58172 12.1128 9.29222 11.9544L5.99898 10.1497L2.70724 11.9544H2.70799Z" fill="#FEAD4D"/>
    </svg>
)

const SubscriptionPlan = props => {

    const {dividerFill, id, name, features, price, currency, handleBuyClick} = props
    const classes = useStyles()

    return (
        <Card className={classes.planCard}>
            <CardHeader title={name} titleTypographyProps={{align: 'center', className: classes.planCardHeader}}/>
            <HeaderDivider fill={dividerFill}/>
            <CardContent className={classes.planCardContent}>
                <List>
                    {features.map((c, i) => (
                        <ListItem key={i} className={classes.planFeatureItem}>
                            <ListItemIcon className={classes.planFeatureItemIcon}>
                                {c.icon === 'circle' ? <GreenCircleIcon/> : <StarIcon/>}
                            </ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{className: classes.planFeatureItemText}} 
                                primary={c.title}
                                secondary={c.subtitle}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
            <Divider/>
            <CardActions className={classes.planCardActions}>
                <Typography
                    variant="h5"
                    display="block"
                    align="center"
                    className={classes.planFeaturePrice}
                >
                    {Intl.NumberFormat('uz-UZ', {currency}).format(price)} UZS
                </Typography>
                <Button 
                    classes={{root: classes.buyButton, label: classes.buyButtonLabel}} 
                    variant="outlined" color="primary" 
                    onClick={() => handleBuyClick(id)}>
                    Sotib olish
                </Button>
            </CardActions>
        </Card>
    )
}

const BuyCourse = props => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const accessToken = useSelector(selectAccessToken)
    const courseID = useSelector(selectBuyCourseID)
    const profile = useSelector(selectUserProfile)

    const [selectedPlanID, setSelectedPlanID] = useState(null)
    const course = useSelector(state => selectCourseById(state, courseID))
    const prices = (course && course.prices) ? course.prices : []

    useEffect(() => {
        if (courseID && !profile) {
            dispatch(showSignIn())
            dispatch(closeBuyCoursePage());
        }
    }, [profile, courseID])

    if (!profile) {
        return null
    }
    const userID = profile.id

    const handleCloseRequested = e => {
        dispatch(closeBuyCoursePage())
    }

    const closeSelectPaymentDialog = () => {
        setSelectedPlanID(null)
    }

    const payWithPayme = () => {
        // Generate paycom url
        const plan = prices.find(p => p.id === selectedPlanID)
        const merchantID = '60a3bd2a7852bd0ebce1be85'
        let param = `m=${merchantID};ac.course=${courseID};ac.tarif=${selectedPlanID};ac.user=${userID};a=${plan.price * 100}`
        let url = `https://checkout.paycom.uz/${window.btoa(param)}`
        window.open(url)
    }

    const payWithClick = async () => {
        try {
            const subscription = await getSubscription(accessToken, courseID, selectedPlanID)
            const plan = prices.find(p => p.id === selectedPlanID)
            const serviceID = 18105
            const merchantID = 13122
    
            let url = `https://my.click.uz/services/pay?service_id=${serviceID}&merchant_id=${merchantID}&amount=${plan.price}&transaction_param=${subscription.id}&return_url={https://edvi.uz}&card_type=uzcard`
            window.open(url)
        }
        catch(e) {
            
        }
    }

    const handleShowPaymentSelection = (planID) => {
        setSelectedPlanID(planID)
    }
    console.log(prices)
    return (
        <>
            <Dialog
                open={Boolean(courseID)}
                onClose={handleCloseRequested}
                maxWidth="lg"
                scroll="body"
            >
                <div className={classes.closeIcon} onClick={handleCloseRequested}>
                    <CloseIcon className={classes.iconClo}/>
                </div>
                <DialogTitle disableTypography>
                    <Typography className={classes.header} align="center">Obunalar</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {[...prices].sort((a, b) => a.price - b.price).map((p, index, array) => (
                        <SubscriptionPlan 
                            key={p.id} 
                            id={p.id}
                            dividerFill={100 * (index + 1) / array.length}
                            name={p.name}
                            features={[]}
                            price={p.price}
                            currency={p.currency} 
                            handleBuyClick={handleShowPaymentSelection}
                        />))}
                </DialogContent>
            </Dialog>
            <Dialog 
                open={selectedPlanID !== null}
                onClose={closeSelectPaymentDialog}
                maxWidth="lg"
                scroll="body"
            >
                <DialogTitle>
                    To`lov usulini tanlang
                    <DialogContent className={classes.paymentDialogContent}>
                        <ButtonBase onClick={payWithPayme}>
                            <img className={classes.paymeLogo} src="/images/payme.png" alt="Payme"/>
                        </ButtonBase>
                        <Button className="click_logo" onClick={payWithClick}><i></i>Click</Button>
                    </DialogContent>
                </DialogTitle>
            </Dialog>
        </>
    )
}


export default BuyCourse