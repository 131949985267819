import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useSelector} from 'react-redux'
import {selectLessonById, selectUnitById, selectVideoById} from '../../redux/coursesSlice'

//  Material-UI
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

//  Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PlaybackLargeBlueIcon from '../../Icons/PlaybackLargeBlue'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
    root: {
        marginTop: 23,
    },
    header: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        padding: '17px 0px',
    },
    title: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '27px',
    },
    unitName: {
        fontSize: 18,
    },
    accordion: {
        padding: '10px 0px',
    },
    summary: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    list: {
        width: '100%',
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    listIcon: {
        minWidth: 0,
        paddingRight: 20,
    },
})

const LessonItem = props => {
    const classes = useStyles()
    const {lesson: lessonID} = props
    const lesson = useSelector(state => selectLessonById(state, lessonID))
    const videos = useSelector(state => lesson.videos.map(videoID => selectVideoById(state, videoID)))
    const duration = videos.reduce((acc, cur) => acc + cur.duration, 0)
    return (
        <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listIcon}>
                <PlaybackLargeBlueIcon/>
            </ListItemIcon>
            <ListItemText
                primary={lesson.title}
                primaryTypographyProps={{
                    variant: 'subtitle2',
                }}
            />
            {/* <ListItemText
                primary={duration.toHHMMSS()}
                primaryTypographyProps={{
                    align: 'right',
                    variant: 'subtitle2'
                }}
            /> */}
        </ListItem>
    )
}

const getUnitTitle = position => {
    if (position === 0) {
        return 'Unit.'
    }
    else {
        return `Unit ${position}.`
    }
}

const CoursePlan = props => {
    const {units: unitIDs} = props
    const classes = useStyles()
    const units = useSelector(state => unitIDs.map(unitID => selectUnitById(state, unitID)))
    const lessonsCount = units.reduce((acc, curr) => acc + curr.lessons.length, 0)
    return (
        <section className={classes.root}>
            <header className={classes.header}>
                <Typography variant="h3" className={classes.title}>Course plan</Typography>
                <Typography variant="subtitle2"> {lessonsCount} lessons | 12 h 10 min</Typography>
            </header>
            
            <main>
                
                {units.map(unit => (
                    <Accordion key={unit.id} className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} classes={{content: classes.summary}}>
                            <Typography className={classes.unitName}><b style={{marginRight: 10}}>{getUnitTitle(unit.position)}</b> {unit.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List className={classes.list}>
                                {unit.lessons.map(lessonId => <LessonItem key={lessonId} lesson={lessonId}/>)}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                ))}
                
            </main>
        </section>
    )
}


export default CoursePlan