import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 15,
        width: 100,
        height: 100,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `3px solid ${theme.palette.primary.main}`, //B7B7B7
        borderColor: props => props.color ?? theme.palette.primary.main,
        '@media (max-width:600px)':{
            width: 115,
        height: 115,
        }
    },
    text: {
        fontSize: 30,
        fontWeight: 600,
        lineHeight: '48px',
        color: props => props.color ?? theme.palette.primary.main,
        '@media (max-width:600px)':{
            fontSize: 38
        }
    },
}))

const Circle = props => {
    const classes = useStyles(props)
    const {progress} = props
    return (
        <div className={classes.root}>
            <Typography className={classes.text} color="primary">
                {progress}%
            </Typography>
        </div>
    )
}

Circle.propTypes = {
    progress: PropTypes.number.isRequired,
    color: PropTypes.string
}

export default Circle