import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import SignIn from './SignIn'
import SignUp from './SignUp'
import LogIn from './LogIn'

import { resetError, selectUserStateStatus } from '../../redux/userStateSlice'
import { selectAuthFormState, closeAuthForm, selectShowLogIn } from '../../redux/appStateSlice'
import { resetCoursesLoadStatus } from '../../redux/coursesSlice'
import { makeStyles } from '@material-ui/core'
import Congratulations from './Congratulations'

const useStyles = makeStyles({
    rad:{
        borderRadius: 14
    }
})

const AuthForm = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const show = useSelector(selectAuthFormState)
    const authStatus = useSelector(selectUserStateStatus)
    const needToShowLogIn = useSelector(selectShowLogIn)

    useEffect(() => {
        if (authStatus === 'fulfilled' && show === 'signin') {
            dispatch(resetCoursesLoadStatus())
            dispatch(closeAuthForm())
        }
    }, [authStatus, dispatch])

    const pending = authStatus === 'pending'

    const handleCloseDialog = () => {
        if (!pending) {
            dispatch(resetError())
            // dispatch(closeAuthForm())
        }
    }
    const CloseDialog = () => {
        dispatch(resetError())
        dispatch(closeAuthForm())
    }

    let component
    if (needToShowLogIn) {
        component = <LogIn/>
    }
    else if (show === 'signin') {
        component = <SignIn close={CloseDialog}/> 
    }
    else if (show === 'signup') {
        component = <SignUp close={CloseDialog}/>
    }
    else {
        return null
    }

    return (
        <>
            <Dialog classes={{paperScrollPaper: classes.rad}} open={true} onClose={handleCloseDialog}>
                <div>
                    {component}
                </div>
            </Dialog>
            <Dialog 
                open={pending} 
                PaperProps={{
                    elevation: 0,
                    style: {backgroundColor: 'transparent'}
                }}
            >
                <CircularProgress color="primary" size={100}/>
            </Dialog>
            {
                (show === 'signup' && 
                authStatus === 'fulfilled') && 
                <Dialog 
                    classes={{paperScrollPaper: classes.rad}} 
                    open={true} 
                    onClose={handleCloseDialog}>
                        <div>
                            <Congratulations />
                        </div>
                </Dialog> 
            }
        </>
    )
}


export default AuthForm