import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { selectExerciseById, selectLessonById } from '../../../redux/coursesSlice'
import { asyncSaveUserProgress } from '../../../redux/userStateSlice'
import getStarsCount from "../../../utils/getStarsForProgress";
import ExerciseQuiz from './ExerciseQuiz';
import ExerciseFillTheGaps from './ExerciseFillTheGaps'
import { makeStyles } from '@material-ui/core'

export const ExerciseCategory = {
    Quiz: 0,
    CompleteToBe: 1,
}

const useStyles = makeStyles({
    main:{
        margin: 0,
        '@media (max-width: 600px)':{
            margin: '0 20px 30px 20px'
        }
    }
})

const ExercisesList = ({className, lessonID}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const lesson = useSelector(state => selectLessonById(state, lessonID))
    const exercisesIDs = lesson ? lesson.exercises : []
    const exercises = useSelector(state => exercisesIDs.map(exerciseID => selectExerciseById(state, exerciseID))).sort((a, b) => a.position - b.position)
    const [error, setError] = useState(null)
    const quiz = exercises.filter(e => e.type === ExerciseCategory.Quiz)
    const completeSentence = exercises.filter(e => e.type === ExerciseCategory.CompleteToBe)
    const [progressValue, setProgressValue] = useState({})

    useEffect(() => {
        let newProgressValues = {}
        for(const e of exercises) {
            const id = e.id
            newProgressValues[id] = 0
        }
        const values = Object.values(newProgressValues)
        if (values.length !== exercises.length) {
            console.error(`Assertion 1: exercises.length(${exercises.length}) != newProgressValues.length(${values.length})`)
            console.log(exercises)
            console.log(values)
            return
        }
        console.log('Progress values formed. length is ', values.length)
        setProgressValue(newProgressValues)
    }, [])


    useEffect( () => {
        try {
            if (exercises.length === 0) {
                return
            }

            let progress = 0
            for(const ex of exercises)  {

                if (ex.id in progressValue) {
                    const stars = getStarsCount(progressValue[ex.id])
                    if (stars === 0) {
                        console.warn(`Exam ${ex.id}:${ex.title} not passed`)
                        return
                    }
                    progress += progressValue[ex.id]
                }
                else {
                    console.warn('Not all exams has been passed')
                    return
                }
            }

            progress /= exercises.length
            console.info(`Lesson ${lessonID} progress: ${progress}`)
            dispatch(asyncSaveUserProgress(lessonID, progress))
        }
        catch (e) {

        }

    }, [progressValue])

    if (exercises.length === 0) {
        return null
    }
    
    const handleSaveProgress = (id, value) => {
        setProgressValue(old => ({...old, [id]: value}))
    }

    return (
        <div className={classes.main}>
            {error && <h1 style={{color: 'red'}}>If you see this message, please take a screenshot and send administrator: {error}</h1>}
            {exercises.map(e => {
                switch (e.type) {
                    case ExerciseCategory.Quiz:
                        return <ExerciseQuiz key={e.id} lessonID={lessonID} exercise={e} onSaveProgress={v => handleSaveProgress(e.id, v)}/>
                    case ExerciseCategory.CompleteToBe:
                        return <ExerciseFillTheGaps key={e.id} lessonID={lessonID} exercise={e} onSaveProgress={v => handleSaveProgress(e.id, v)}/>
                    default:
                        return null
                }
            })}
        </div>
    )
}

ExercisesList.propTypes = {
    lessonID: PropTypes.number,
    className: PropTypes.string
}

export default ExercisesList